

  
<mat-drawer-container class="example-container" autosize>
  <!-- <mat-drawer #drawer class="example-sidenav" mode="over" [style.background-color]=colorMenu [style.color]=colorTextoMenu>

    <div class="titulo" [style.color]=colorTextoMenu>
      <h2 *ngFor="let zona of listZona">{{zona.strNombre}}</h2>
      <mat-icon matBadge="{{totalArticulos}}" matBadgeColor="warn" class="carrito2" *ngIf="muestraTotalArticulos">shopping_cart</mat-icon>
    </div>
    <br>

    <button mat-raised-button (click)="addArticulos()" [style.background-color]=colorMenu [style.color]=colorTextoMenu>
      Ver mas artículos
    </button>

  </mat-drawer> -->
  <mat-toolbar [style.background-color]=colorMenu [style.color]=colorTextoMenu class="example-toolbar">
    <div [style.background-color]=colorMenu [style.color]=colorTextoMenu class="cabecera">

      <button mat-icon-button id="cierraMenu" (click)="snav.toggle()" ><mat-icon>menu</mat-icon></button>

      <div class="datosEstablecimiento">
        <img src="data:image/gif;base64,{{miEstablecimiento.strLogo}}" (click)="verDestacados()">
        <!-- <div class="establecimiento">
          {{miEstablecimiento.strNombre}} 
        </div> -->
        <mat-icon matBadge="{{totalArticulos}}" matBadgeColor="warn" class="carrito" *ngIf="muestraTotalArticulos">shopping_cart</mat-icon>
      </div>
    </div>
  </mat-toolbar>

  <mat-sidenav-container>
    <mat-sidenav #snav class="example-sidenav" mode="side" opened="false" [style.background-color]=colorMenu>
      <div class="no-v-scroll">
        <mat-nav-list>
    
          <mat-list-item style="height: 100%;">
            <span class="full-width miMenu" style="font-size: 13px; max-width: 160px; font-weight: 500; color:grey"><i style="vertical-align: super; font-weight: 700;">...</i> {{strZona}}</span>
          </mat-list-item>

          <mat-list-item style="height: 100%; width: 100%;">
            <span class="full-width miMenu" style="font-size: 18px; max-width: 160px; font-weight: 500;" [style.color]=colorTextoMenu>{{strMesa}}</span>&nbsp;&nbsp;
            <mat-icon matBadge="{{totalArticulos}}" matBadgeColor="warn" class="carrito2" *ngIf="muestraTotalArticulos" [style.color]=colorTextoMenu>shopping_cart</mat-icon>
          </mat-list-item>

          <mat-list-item style="height: 100%;" (click)="addArticulos()">
            <span class="full-width miMenu" style="font-size: 16px; max-width: 160px; font-weight: 300;" [style.color]=colorTextoMenu>Ver más artículos</span>
          </mat-list-item>

          <!-- <mat-list-item style="height: 100%;" (click)="getDestacado()">
            <span class="full-width miMenu" style="font-size: 16px; max-width: 160px; font-weight: 500;" [style.color]=colorTextoMenu>Destacados</span>
          </mat-list-item>

          <mat-list-item style="height: 100%;">
            <span class="full-width miMenu" style="font-size: 13px; max-width: 160px; font-weight: 500; color:grey"><i style="vertical-align: super; font-weight: 700;">...</i> Grupos</span>
          </mat-list-item>

          <mat-list-item style="height: 100%;" *ngFor="let grupo of listaGrupos" value="grupo.intId" (click)="getGrupo(grupo)">
            <span class="full-width miMenu" style="font-size: 16px; max-width: 160px; font-weight: 300;" [style.color]=colorTextoMenu>{{grupo.strNombre}}</span>
          </mat-list-item> -->

          <br><br>
        </mat-nav-list>
      </div>
    </mat-sidenav>

    <mat-sidenav-content>
      <!-- <div class="example-sidenav-content"> -->

        <!-- <div [style.background-color]=colorMenu [style.color]=colorTextoMenu class="cabecera">
          <button type="button" id="cierraMenu" mat-button (click)="drawer.toggle()">
            <mat-icon>menu</mat-icon>
          </button>
    
          <div class="datosEstablecimiento">
            <img src="data:image/gif;base64,{{miEstablecimiento.strLogo}}">
            <div class="establecimiento">
              {{miEstablecimiento.strNombre}}
            </div>
            <mat-icon matBadge="{{totalArticulos}}" matBadgeColor="warn" class="carrito" *ngIf="muestraTotalArticulos">shopping_cart</mat-icon>
          </div>
        </div> -->
    
        <section [style.background-color]=colorCuerpo [style.color]=colorTextoCuerpo class="contenidoResumen">
          
          <div [style.background-color]=colorCuerpo class="estilosTituloPagina">
            <!-- <span class="zona2">
                {{strZona}} - 
            </span> -->
            Resumen
          </div>
    
          <section *ngIf="myLoader"  class="miLoader">
    
            <svg viewBox="-2000 -1000 4000 2000">
              <path id="inf" d="M354-354A500 500 0 1 1 354 354L-354-354A500 500 0 1 0-354 354z">
              </path>
              <use xlink:href="#inf" stroke-dasharray="1570 5143" stroke-dashoffset="6713px">
              </use>
            </svg>
          </section>
    
          <div class="eliminar">
            <span (click)="eliminar()" class="elimina"> Eliminar</span>
          </div>

          <ul class="tiposLinea" >
            <li *ngFor="let miTipoLinea of miListTipoLinea"> 
              <span [style.background-color]="miTipoLinea.strColor" (click)="cambiaTipoLinea(miTipoLinea)">&nbsp; &nbsp; &nbsp;</span> 
              <span (click)="cambiaTipoLinea(miTipoLinea)">{{miTipoLinea.strNombre}}</span>
            </li>
          </ul>

          <br>
    
          <table class="listArticulos" *ngFor="let l of listPedidoLineas">
            <tr class="separacion">
              <td class="textoMuestra">
                <!-- <div class="checkbox"></div> -->
                <div class="nombrePato"> 
                  <mat-checkbox [(ngModel))]="l.intId" (change)="marcarItem($event,l)" *ngIf="l.strDescargado === '' ? true : false"></mat-checkbox> 
                  <div (click)="modificaCantidad(l)">&nbsp; <span [style.color]="l.strColor" style="font-weight: 600;">{{l.strTextoPosicion}}</span> {{l.strArticuloNombre}} 
                    <span style="float: right;">{{l.decImporte}}€  
                      <span class="dot">{{l.decCantidad}}</span>
                    </span>
                  </div>
                  <div *ngFor="let LM of l.miListLineaMenu">
                    &nbsp;&nbsp;&nbsp; - {{LM.strArticuloNombre}}
                  </div>
                </div>
                <!-- <table>
                  <tr>
                    <td class="nombrePlato"><mat-checkbox [(ngModel))]="l.intId" (change)="marcarItem($event,l)"></mat-checkbox>  </td>
                    <td class="nombrePlato" style="background-color: tomato; width: 95%;"><span (click)="modificaCantidad(l)" style="background-color: teal; width: 100%;"> {{l.strArticuloNombre}}</span></td>
                  </tr>
                  <tr (click)="modificaCantidad(l)">
                    <td class="precioArticulo">
                      {{l.decCantidad}} x {{l.decPvp}} € = {{l.decImporte}} €
                    </td>
                  </tr>
                </table> -->
                <!-- <table  (click)="modificaCantidad(l)">
                  <tr>
                    <td>&nbsp;&nbsp;&nbsp;<img src="data:image/gif;base64,{{l.strBase64}}" width="80px"></td>
                  </tr>
                </table> -->
                <!-- <div class="btnAdd">
                  <img src="../../assets/minus-26.png" width="25" height="25" (click)="menos(l)">              
                  <span> {{l.decCantidad}} </span>
                  <img src="../../assets/add-24.png" width="25" height="25" (click)="mas(l)">
                </div> -->
              </td>
            </tr>
          </table>
          <br><br>
          <div class="resumenPedido">
            <div class="left"><b>Importe total: </b></div> <div class="right"><span>{{miPedido.decImporte}} €</span></div>
          </div>
          <br>
          <ul class="botonesSecundarios">
            <!-- <li class="acepta" (click)="finalizaPedido()">Aceptar</li> -->
            <li><button mat-raised-button color="warn" (click)="finalizaPedido()">Aceptar</button></li>
          </ul>
          <br><br><br><br>         
        </section>
      <!-- </div> -->
    </mat-sidenav-content>
  </mat-sidenav-container>

  
  <app-footer></app-footer>
</mat-drawer-container>
