import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppMaterialModule } from './app-material.module';
// import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatNativeDateModule, MatPaginatorIntl } from '@angular/material';
import { CustomPaginator } from './resources/Funciones';
import { DatePipe } from '@angular/common';
import { ConfiguracionesComponent } from './components/configuraciones/configuraciones.component';
import { ArticulosComponent } from './components/articulos/articulos.component';
import { DestacadosComponent } from './components/destacados/destacados.component';
import { DetalleComponent } from './components/detalle/detalle.component';
import { FooterComponent } from './components/footer/footer.component';
import { InicioComponent } from './components/inicio/inicio.component';
import { LayoutComponent } from './components/layout/layout.component';
import { LayoutMenuComponent } from './components/layout-menu/layout-menu.component';
import { MensajeErrorComponent } from './components/mensaje-error/mensaje-error.component';
import { MenuListItemComponent } from './components/menu-list-item/menu-list-item.component';
import { NombreClienteComponent } from './components/nombre-cliente/nombre-cliente.component';
import { PoliticaCookiesComponent } from './components/politica-cookies/politica-cookies.component';
import { ResumenComponent } from './components/resumen/resumen.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { CartaOnlineService } from './services/carta-online.service';
import { StorageService } from './services/storage.service';
import { VentanaEmergenteComponent } from './ventana-emergente/ventana-emergente.component';
import { VentanaNumArticulosComponent } from './components/ventana-num-articulos/ventana-num-articulos.component';
import { VentanaNumLineasComponent } from './components/ventana-num-lineas/ventana-num-lineas.component';

@NgModule({
  declarations: [
    AppComponent,
    ConfiguracionesComponent,
    ArticulosComponent,
    DestacadosComponent,
    DetalleComponent,
    FooterComponent,
    InicioComponent,
    LayoutComponent,
    LayoutMenuComponent,
    MensajeErrorComponent,
    MenuListItemComponent,
    NombreClienteComponent,
    PoliticaCookiesComponent,
    ResumenComponent,
    SidenavComponent,
    VentanaEmergenteComponent,
    VentanaNumArticulosComponent,
    VentanaNumLineasComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AppMaterialModule,
    // HttpModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatNativeDateModule
  ],
  providers: [CartaOnlineService, StorageService, {provide: MatPaginatorIntl, useValue: CustomPaginator() },DatePipe
              
             ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
