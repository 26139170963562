import { Component, OnInit, Inject } from '@angular/core';
import { CartaOnlineService } from 'src/app/services/carta-online.service';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { DataCookie } from 'src/app/models/data-cookie';

@Component({
  selector: 'app-politica-cookies',
  templateUrl: './politica-cookies.component.html',
  styleUrls: ['./politica-cookies.component.scss']
})
export class PoliticaCookiesComponent implements OnInit {

  colorCuerpo:string;
  colorTextoCuerpo:string;
  politicaCookies:string;

  constructor(private miservicio:CartaOnlineService, private thisDialogRef: MatDialogRef<MatDialog>, @Inject(MAT_DIALOG_DATA) private data: DataCookie) {
  }

  ngOnInit() {
    this.datosEstablecimiento();
    this.getPoliticaCookies();
  }

  datosEstablecimiento(){
    // this.miservicio.getEstablecimiento(sessionStorage.getItem('strCookieCartaOnline')).subscribe(datos=>{     
    //   // console.log(datos[0])
    //   this.colorCuerpo="#"+datos[0].strColorCuerpo;
    //   this.colorTextoCuerpo="#"+datos[0].strColorTextoCuerpo;

    //   // console.log(this.colorTextoCuerpo)
    // })
    this.colorCuerpo=this.data.strColorCuerpo;
    this.colorTextoCuerpo=this.data.strColorTextoCuerpo;
  }

  getPoliticaCookies(){
    this.miservicio.getPoliticaCookies(this.data.coo).subscribe((datos:any)=>{
      // console.log(datos)
      this.politicaCookies=datos.strPoliticaCookies;
    })
  }

  aceptar(){
    this.thisDialogRef.close(true);
  }

  cancelar(){
    this.thisDialogRef.close(false);
  }

}
