import { MatPaginatorIntl } from '@angular/material';

export class Funciones  {

    // constructor(private storageService:StorageService) { }

    DesencriptaUsuarioPassword(strDatoEncriptado: string): string {
        let strDatoDesencriptado: string = "";   
            
        if (strDatoEncriptado != null && strDatoEncriptado != "") {
            strDatoEncriptado = strDatoEncriptado.split('').reverse().join('');
            strDatoEncriptado = strDatoEncriptado.substr(5, strDatoEncriptado.length - (2 * 5));
            
            let i: number;
            for (i = 0; i < strDatoEncriptado.length; i++) {
                if (i % 2 == 0) { strDatoDesencriptado += strDatoEncriptado.substr(i, 1); }
            }
        }
        return strDatoDesencriptado;
    }

    nif(dni) {
        var validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
        var nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
        var nieRexp = /^[XYZ]{1}[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
        var str = dni.toUpperCase();
    
        if (!nifRexp.test(str) && !nieRexp.test(str)) return false;
    
        var nie = str
            .replace(/^[X]/, '0')
            .replace(/^[Y]/, '1')
            .replace(/^[Z]/, '2');
    
        var letter = str.substr(-1);
        var charIndex = parseInt(nie.substr(0, 8)) % 23;
    
        if (validChars.charAt(charIndex) === letter) return true;
    
        return false;
      }      
      
}

//Cambiar el label del elemento paginator de la tabla
export function CustomPaginator() {
    const customPaginatorIntl = new MatPaginatorIntl();
  
    customPaginatorIntl.itemsPerPageLabel = 'Items por pagina';
  
    return customPaginatorIntl;
}