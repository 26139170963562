import { ArticuloAlergeno } from "./articulo-alergeno";

export class Articulo {

    // public intId?:number;
    // public strNombre?:string;
    // public strDescripcion?:string;
    // public intGrupoId?:number;
    // public strGrupoNombre?:string;
    // public decIva?:number;
    // public strActivo?:string;
    // public strDestacado?:string;
    // public strArticuloIdExt?:string;
    // public intTipo?:number;
    // public strDescargado?:string;
    // public decTarifa?:number;
    // public strAlergenos?:string;
    // public strImagen?:string;

    // public strAlergenosRecortado?:string;
    // public strNombreRecortado?:string;
    // public strDescripcionRecortada?:string;

    public strArticuloIdExt?: string;
    public decTarifa?: number;
    public intId?: number;
    public strNombre?: string;
    public strDescripcion?: string;
    public intIdGrupo?: number;
    public strNombreGrupo?: string;
    public strActivo?: string;
    public strDestacado?: string;
    public strIdExterno?: string;
    public intTipo?: number;
    public strDescargado?: string;
    // public strAlergenos?: string;
    public decIvaCompra?: number;
    public decPc1?: number;
    public decPc2?: number;
    public decPc3?: number;
    public decPc4?: number;
    public decPc5?: number;
    public decIvaVenta?: number;
    public decPv1?: number;
    public decPv2?: number;
    public decPv3?: number;
    public decPv4?: number;
    public decPv5?: number;
    public decPvp1?: number;
    public decPvp2?: number;
    public decPvp3?: number;
    public decPvp4?: number;
    public decPvp5?: number;
    public strImagen1Base64?: string;
    public strImagen2Base64?: string;
    public strImagen3Base64?: string;
    public strImagen4Base64?: string;
    public strImagen5Base64?: string;
    public strImagen1MiniBase64?: string;
    public strNombreRecortada?: string;
    public strDescripcionRecortada?: string;
    // public strAlergenosRecortada?: string;
    public booMuestraImagen?: boolean;

    public miListArticuloAlergeno: ArticuloAlergeno[] = [];
        
}

