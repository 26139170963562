import { Component, OnInit, HostBinding, Input, HostListener } from '@angular/core';
import { trigger, animate, style, state, transition } from '@angular/animations';
import { Grupo } from 'src/app/models/grupo';
import { Articulo } from 'src/app/models/articulo';
import { Establecimiento } from 'src/app/models/establecimiento';
import { Zona } from 'src/app/models/zona';
import { environment } from 'src/environments/environment';
import { CartaOnlineService } from 'src/app/services/carta-online.service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { MatDialog, MatRadioChange } from '@angular/material';
import { DetalleComponent } from '../detalle/detalle.component';
import { MensajeErrorComponent } from '../mensaje-error/mensaje-error.component';
import { Mesa } from 'src/app/models/mesa';
import { VentanaEmergenteComponent } from 'src/app/ventana-emergente/ventana-emergente.component';
import { NombreClienteComponent } from '../nombre-cliente/nombre-cliente.component';
import { DataCookie } from 'src/app/models/data-cookie';
import { CookieService } from 'ngx-cookie-service';
import { DataCookiePedido } from 'src/app/models/data-cookie-pedido';
import { VentanaNumArticulosComponent } from '../ventana-num-articulos/ventana-num-articulos.component';
import { parse } from 'querystring';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({transform: 'rotate(0deg)'})),
      state('expanded', style({transform: 'rotate(180deg)'})),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ]
})
export class SidenavComponent implements OnInit {

  abierto:boolean=true;
  cerrado:boolean=false;

  grupoId:any;
  listaGrupos:Grupo[]=[];

  miListGruposMenu:Grupo[]=[];

  miListGrupoMenu:Grupo[]=[];
  miListArticulosMenuPedido:Articulo[]=[];
  idArticuloMenuPedido:any;
  btnMenu=false;

  listArticulos2:Articulo[]=[];
  miEstablecimiento:Establecimiento= new Establecimiento();
  miZona:Zona= new Zona();

  colorCuerpo:string;
  colorMenu:string;
  colorTextoCuerpo:string;
  colorTextoMenu:string;

  miGrupo:Grupo=new Grupo();

  nombreGrupo:string;
  sinDatos:boolean=false;
  myLoader:boolean=false;

  muestraTotalArticulos:boolean=false;
  totalArticulos:number;

  cookieNombre:string=environment.strCookie;
  midataCookie:DataCookie=new DataCookie();
  aceptCookies=false;
  cookieNombreNavegador=environment.strCookieNavegador;
  intIdPedido:string=environment.intIdPedido;
  cookiePedido=environment.strCookiePedido;
  midataCookiePedido:DataCookiePedido=new DataCookiePedido();

  strMesa:string;
  strZona:string;

  miMesa:Mesa=new Mesa();
  strClave:string;
  idEstablecimiento:string="";

  showBtnComprar=false;
  IsCookiePedidoExists:boolean;

  strContrasenaDigitada:any;

  miArticuloConPrecio:Articulo=new Articulo();
  esMenu=false;

  constructor(private miservicio:CartaOnlineService,private router: Router,private route: ActivatedRoute,public dialog: MatDialog,private cookieService: CookieService) { 
    this.route.queryParams.subscribe(params => {
      this.idEstablecimiento=params["id"];
    });
  }

  ngOnInit() {
    let IsCookieExists:boolean=this.cookieService.check(this.cookieNombre+this.idEstablecimiento);
    if(IsCookieExists){
      this.midataCookie=JSON.parse(this.cookieService.get(this.cookieNombre+this.idEstablecimiento))
      this.ComprobarCookiesNavegador();
      this.IsCookiePedidoExists=this.cookieService.check(this.cookiePedido+this.idEstablecimiento);
      if(this.IsCookiePedidoExists){
        this.midataCookiePedido=JSON.parse(this.cookieService.get(this.cookiePedido+this.idEstablecimiento));
      }
      setTimeout(()=>{
        this.colorCuerpo=this.midataCookie.strColorCuerpo;
        this.colorTextoCuerpo=this.midataCookie.strColorTextoCuerpo;
        this.colorMenu=this.midataCookie.strColorMenu;
        this.colorTextoMenu=this.midataCookie.strColorTextoMenu;
        this.strMesa=this.midataCookie.nombreMesa;
        this.strZona=this.midataCookie.nombreZona;
        this.recuperaTodosDatos();
      }, 500)
    }else{
      this.mensajeError("La cookie ha caducado, por favor escanee nuevamente el codigo QR");
    }
    
    this.myLoader=true;
    if(this.nombreGrupo===undefined){
      this.nombreGrupo="Destacados";
    }
  }

  removeCookiePedido(){
    this.cookieService.delete(this.cookiePedido+this.idEstablecimiento,'',window.location.hostname);
  }

  recuperaTodosDatos(){

    if(this.IsCookiePedidoExists){
      this.miservicio.getLineasPedido(this.midataCookie.coo,this.midataCookiePedido.intPedidoId,this.midataCookie.mes).subscribe((datos:any)=>{
        // console.log(datos)
        if(datos.length>0){
          this.totalArticulos=datos[datos.length-1].decTotalArticulos;
          this.muestraTotalArticulos=true;
        }
      })
    }

    //unico rest para recuperar los datos de mesa, establecimiento, grupos, articulos
    this.miservicio.getDatosVarios(this.midataCookie.coo,this.midataCookie.mes).subscribe(datos=>{
      // console.log(datos)

      if(datos.miRespuesta.booOk){
        this.miMesa=datos.miListMesa[0];
        this.miEstablecimiento=datos.miListEstablecimiento[0];
        this.listaGrupos=datos.miListGrupo;
        this.miListGruposMenu=datos.miListGruposMenu;

        if(this.miEstablecimiento.strClave===null){this.miEstablecimiento.strClave=""}
        
        this.myLoader=false;

        //Si no tiene articulos destacados muestro mensaje
        if(datos.miListArticulo.length>0){
          this.listArticulos2=datos.miListArticulo;
        }
        else{
          this.sinDatos=true;
        }

      }else{
        this.myLoader=false;
        this.mensajeError(datos.miRespuesta.strMensaje);
      }
    })    
  }

  //PARA COOKIES DEL NAVEGADOR
  ComprobarCookiesNavegador(){
    let IsCookieExists:boolean=this.cookieService.check(this.cookieNombreNavegador+this.idEstablecimiento);
    if(IsCookieExists){
      this.aceptCookies=false;
    }else{
      this.aceptCookies=true;
    }
  }

  recuperoTotalArticulos(){    
    if(this.IsCookiePedidoExists){
      this.miservicio.getLineasPedido(this.midataCookie.coo,this.midataCookiePedido.intPedidoId,this.midataCookie.mes).subscribe((datos:any)=>{
        // console.log(datos.length)
        
        if(datos.length>0){
          this.totalArticulos=datos[datos.length-1].decTotalArticulos;
          this.muestraTotalArticulos=true;
        }
      })
    }
    this.myLoader=false;
  }

  radioChange(event) {
    if(this.miListArticulosMenuPedido.find(a=>a.intIdGrupo===parseInt(event.target.name))){
      //Elimino el que ya existia
      let index=this.miListArticulosMenuPedido.findIndex(art=>art.intIdGrupo===parseInt(event.target.name));
      this.miListArticulosMenuPedido.splice(index,1);
      //Inserto el nuevo
      this.miListArticulosMenuPedido.push((this.miListGrupoMenu.find(g=>g.intId===parseInt(event.target.name)).miListArticulo.find(a=>a.intId===parseInt(event.target.id))))
    }else{
      this.miListArticulosMenuPedido.push((this.miListGrupoMenu.find(g=>g.intId===parseInt(event.target.name)).miListArticulo.find(a=>a.intId===parseInt(event.target.id))))
    }
  }

  pedirMenu(){
    // console.log(this.midataCookiePedido.strClavePedido)
      if(this.midataCookiePedido.strClavePedido!=undefined && this.midataCookiePedido.strClavePedido!=""){
        this.myLoader=true;
        this.miservicio.insertaPedidoMenu(this.midataCookie.coo,this.midataCookiePedido.intPedidoId,this.midataCookie.mes,this.miListArticulosMenuPedido,"",1,this.idArticuloMenuPedido).subscribe((datos:any)=>{
          // console.log(datos)
          if(datos.miRespuesta.booOk){
            if(!this.IsCookiePedidoExists){
              this.midataCookiePedido.intPedidoId=datos.miListPedidos[0].intId;
              this.midataCookiePedido.strClavePedido=datos.miRespuesta.strValor;
              this.cookieService.set(this.cookiePedido+this.idEstablecimiento,JSON.stringify(this.midataCookiePedido),1,'',window.location.hostname);
            }
            this.totalArticulos=datos.miListPedidoLinea[datos.miListPedidoLinea.length-1].decTotalArticulos;
            this.muestraTotalArticulos=true;
            this.myLoader=false;
          }else{
            // this.mensajeError(datos.miRespuesta.strMensaje);
            // this.myLoader=false;
            this.pideContrasena("",1,this.idArticuloMenuPedido,this.miListArticulosMenuPedido);
            // this.removeCookiePedido();
            // this.midataCookiePedido=new DataCookiePedido();
          }
        })
      }else{
        //Compruebo si existe pedido en curso para esa mesa
        let dialogRef = this.dialog.open(NombreClienteComponent, {
          width: '400px'      
        });
        dialogRef.afterClosed().subscribe(nombre => {
          if(nombre!=undefined){
            //Compruebo cabecera del pedido, si no existe la creo para guardar la contraseña. Si existe verifico y actualizo contraseña
            this.miservicio.verificaPassPedido(this.midataCookie.coo,this.midataCookie.mes,nombre,"",1,this.idArticuloMenuPedido,this.miListArticulosMenuPedido).subscribe(data=>{
              // console.log(data)

              if(data.miRespuesta.booOk){
                this.myLoader=true;
                //Si existiese una cookie anterior la elimino
                this.removeCookiePedido();

                //Guardo la cookie
                this.midataCookiePedido.intPedidoId=data.miListPedidos[0].intId;
                this.midataCookiePedido.strClavePedido=data.miRespuesta.strValor;
                this.cookieService.set(this.cookiePedido+this.idEstablecimiento,JSON.stringify(this.midataCookiePedido),1,'',window.location.hostname);

                if(data.miListPedidoLinea.length>0){
                  this.totalArticulos=data.miListPedidoLinea[data.miListPedidoLinea.length-1].decTotalArticulos;
                  this.muestraTotalArticulos=true;
                }
                // this.miservicio.insertaPedidoMenu(this.midataCookie.coo,'',this.midataCookie.mes,this.miListArticulosMenuPedido,nombre,1,this.idArticuloMenuPedido).subscribe(datos=>{  
                //   if(datos.miRespuesta.booOk){
                //     this.totalArticulos=datos.miListPedidoLinea[datos.miListPedidoLinea.length-1].decTotalArticulos;
                //     this.muestraTotalArticulos=true;
                //     this.myLoader=false;
                //   }else{
                //     this.myLoader=false;
                //     this.mensajeError(datos.miRespuesta.strMensaje);
                //   }
                // })
              }
              else{
                this.mensajeError(data.miRespuesta.strMensaje);
              }
            })
          }
        });
      }
  }

  mas(a){
    // console.log(this.midataCookiePedido.strClavePedido)
      if(this.midataCookiePedido.strClavePedido!=undefined && this.midataCookiePedido.strClavePedido!=""){
        // console.log(this.addArticuloPedido(a));
        let dialogRef = this.dialog.open(VentanaNumArticulosComponent, {
          width: '600px',
          data: a
        });
        dialogRef.afterClosed().subscribe(response => {
          if(response!=undefined){
            this.myLoader=true;
            this.miservicio.putPedido(this.midataCookie.coo,this.midataCookiePedido.intPedidoId,this.midataCookie.mes,a,"",response).subscribe((datos:any)=>{
              // console.log(datos)
              if(datos.miRespuesta.booOk){
                if(!this.IsCookiePedidoExists){
                  this.midataCookiePedido.intPedidoId=datos.miListPedidos[0].intId;
                  this.midataCookiePedido.strClavePedido=datos.miRespuesta.strValor;
                  this.cookieService.set(this.cookiePedido+this.idEstablecimiento,JSON.stringify(this.midataCookiePedido),1,'',window.location.hostname);
                }
                this.totalArticulos=datos.miListPedidoLinea[datos.miListPedidoLinea.length-1].decTotalArticulos;
                this.muestraTotalArticulos=true;
                this.myLoader=false;
                // this.recuperoTotalArticulos();          
              }else{
                this.pideContrasena(a.intId,response,"","");
              }
            })
          }
        });
       
      }else{
        this.myLoader=true;
        this.pideContrasena(a.intId,1,"","");
      }
  }

  pideContrasena(articulo,cantidad,articuloExt,listArticulosDeMenu){
    let dialogRef = this.dialog.open(NombreClienteComponent, {
      width: '400px'      
    });
    dialogRef.afterClosed().subscribe(nombre => {
      if(nombre!=undefined){
        // this.strContrasenaDigitada=nombre;
        // console.log(articulo)
        this.verificaContrasenaDigitada(nombre,articulo,cantidad,articuloExt,listArticulosDeMenu);
      }
    });
  }

  verificaContrasenaDigitada(contrasena,articulo,cantidad,articuloExt,ListArt){
    this.miservicio.verificaPassPedido(this.midataCookie.coo,this.midataCookie.mes,contrasena,articulo,cantidad,articuloExt,ListArt).subscribe(data=>{
      // console.log(data)
      this.myLoader=false;
      if(data.miRespuesta.booOk){
        //Si existiese una cookie anterior la elimino
        this.removeCookiePedido();

        //Guardo la cookie
        this.midataCookiePedido.intPedidoId=data.miListPedidos[0].intId;
        this.midataCookiePedido.strClavePedido=data.miRespuesta.strValor;
        this.cookieService.set(this.cookiePedido+this.idEstablecimiento,JSON.stringify(this.midataCookiePedido),1,'',window.location.hostname);

        if(data.miListPedidoLinea.length>0){
          this.totalArticulos=data.miListPedidoLinea[data.miListPedidoLinea.length-1].decTotalArticulos;
          this.muestraTotalArticulos=true;
        }

        // this.pideCantidadArticulo(articulo)
      }
      else{
        this.mensajeError(data.miRespuesta.strMensaje);
      }
    })
  }

  // pideCantidadArticulo(articulo){
  //   // console.log(articulo)
  //   //Pido la cantidad para el pedido
  //   let dialogRef = this.dialog.open(VentanaNumArticulosComponent, {
  //     width: '600px',
  //     data: articulo
  //   });
  //   dialogRef.afterClosed().subscribe(response => {
  //     if(response!=undefined)
  //     {
  //       this.insertaPedidoLinea(articulo,'',response);
  //     }
  //   })
  // }

  // insertaPedidoLinea(art,contrasena,cantidadArt){
  //   // console.log(art)
  //   this.miservicio.putPedido(this.midataCookie.coo,'',this.midataCookie.mes,art,contrasena,cantidadArt).subscribe(datos=>{  
  //     // console.log(datos)
  //     if(datos.miRespuesta.booOk){
  //       this.totalArticulos=datos.miListPedidoLinea[datos.miListPedidoLinea.length-1].decTotalArticulos;
  //       this.muestraTotalArticulos=true;
  //       this.myLoader=false;
  //     }else{
  //       this.myLoader=false;
  //       this.mensajeError(datos.miRespuesta.strMensaje);
  //     }
  //   })
  // }

  resumen(){
    let extra: NavigationExtras = {
      queryParams: {
        'id':this.idEstablecimiento
      }
    }
    this.router.navigate(['resumen'], extra);
  }

  datosEstablecimiento(){
    this.miservicio.getEstablecimiento(this.midataCookie.coo).subscribe(datos=>{
      // console.log(datos)
      if(datos.miRespuesta.booOk){
        this.miEstablecimiento=datos.miListEstablecimiento[0];
        this.recuperoTotalArticulos();
      }
    })
  }

  listarArticulos(strSubGrupos){
    if(strSubGrupos===""){this.btnMenu=false;}
    this.listArticulos2.splice(0, this.listArticulos2.length);
    this.miListGrupoMenu.splice(0, this.miListGrupoMenu.length);
    this.miservicio.getArticulos(this.midataCookie.coo,this.midataCookie.mes,0,"",this.grupoId,strSubGrupos).subscribe(datos=>{
      // console.log(datos)
      

      if(datos.miRespuesta.booOk){
        if(strSubGrupos!=""){
          this.btnMenu=true;
          this.miListGrupoMenu=datos.miListGrupoMenu;
          this.miArticuloConPrecio=datos.miArticuloConPrecio;
        }
        else{
          this.btnMenu=false;
          this.listArticulos2=datos.miListArticulo;
        }
        this.myLoader=false;
        this.sinDatos=false;
      }else{
        this.sinDatos=true;
        this.myLoader=false;
      }

    })
  }

  verDestacados(){
    this.nombreGrupo="Destacados";
    this.myLoader=true;
    this.listArticulosDestacados();
  }

  listArticulosDestacados(){
    this.listArticulos2.splice(0, this.listArticulos2.length);
    this.miservicio.getArticulos(this.midataCookie.coo,this.midataCookie.mes,0,"S",0,'').subscribe(datos=>{
      // console.log(datos)
      // this.listArticulos2.splice(0, this.listArticulos2.length);

      if(datos.miRespuesta.booOk){
        this.listArticulos2=datos.miListArticulo;
        this.myLoader=false;
        this.sinDatos=false;
      }else{
        this.sinDatos=true;
        this.myLoader=false;
      }
      
    })
  }

  getGrupo(grupo:Grupo){
    // console.log(grupo)
    this.myLoader=true;
    let strSubGrupos=""

    this.nombreGrupo=grupo.strNombre;
    this.grupoId=grupo.intId;
    
    document.getElementById("cierraMenu").click();
    if(grupo.strIdExtPadre!=null && grupo.strIdExtPadre!=""){
      if(grupo.strIdExtPadre.includes("$MENU$")){
        // strSubGrupos=grupo.strGrupoIdExt.replace("$MENU$-","");
        strSubGrupos=grupo.strGrupoIdExt;
        this.idArticuloMenuPedido=grupo.strGrupoIdExt;
      }
    }
    this.miListArticulosMenuPedido.splice(0,this.miListArticulosMenuPedido.length);
    this.listarArticulos(strSubGrupos);
  }

  getDestacado(){
    this.btnMenu=false;
    this.myLoader=true;
    this.nombreGrupo="Destacados";
    this.listArticulosDestacados();
    document.getElementById("cierraMenu").click();
  }

  verArticulo(a:Articulo){
    let dialogRef = this.dialog.open(DetalleComponent, {
      width: '600px',
      data: {
        'intId':a.intId,
        'coo':this.midataCookie.coo,
        'mes':this.midataCookie.mes,
        'est':this.midataCookie.est
      }
    });
    dialogRef.afterClosed().subscribe(() => {
    });
  }


  openNav() {
    document.getElementById("miSidenav").style.width="250px";
  
    this.abierto=true;
    this.cerrado=false;
  }
   closeNav() {
    document.getElementById("miSidenav").style.width="0";
    
    this.abierto=false;
    this.cerrado=true;
    
  }

  private mensajeError(data:any){
    let dialogRef = this.dialog.open(MensajeErrorComponent, {
      width: '600px',
      data: {
        'strMensaje':data
      }
    });
    dialogRef.afterClosed().subscribe(() => {
    });
   }
}