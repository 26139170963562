import { Component, OnInit, HostListener } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CartaOnlineService } from 'src/app/services/carta-online.service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { MatDialog } from '@angular/material';
import { StorageService } from 'src/app/services/storage.service';
import { DataCookie } from 'src/app/models/data-cookie';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit {

  cookie:string;
  mesa:string;
  idEstablecimiento:string="";
  idProducto:string="";
  //datos:Session[]=[];
  cookieNombre:string=environment.strCookie;
  midataCookie:DataCookie=new DataCookie();
  // midataCookieAux:DataCookie=new DataCookie();
  // MesaId:string=environment.intIdMesa;
  // strNombreZona:string=environment.strNombreZona;
  // strNombreMesa:string=environment.strNombreMesa;

  // strColorMenu:string=environment.strColorMenu;
  // strColorTextoMenu:string=environment.strColorTextoMenu;
  // strColorCuerpo:string=environment.strColorCuerpo;
  // strColorTextoCuerpo:string=environment.strColorTextoCuerpo;

  showInfi=false;
  showError=false;
  strMensajeError:string="";
  booContinuar=false;
  booContinuar2=false;

  constructor(private miservicio:CartaOnlineService,private router: Router,private route: ActivatedRoute,private cookieService: CookieService,
    public dialog: MatDialog,private storageService: StorageService) { 
    this.route.queryParams.subscribe(params => {
      this.cookie=params["coo"];
      this.mesa=params["mes"];
      this.idEstablecimiento=params["est"];
      this.idProducto=params["pro"];
    });
  }

  ngOnInit() {
    // sessionStorage.setItem(this.miCooKie, this.cookie);
    // sessionStorage.setItem(this.MesaId, this.mesa);


    // this.getMesa();

    // setTimeout(() => {
    //   // this.router.navigate(['/CartaOnline']);
    // }, 3000);

    if(this.cookie!=undefined && this.cookie!=""){
      this.booContinuar=true;
    }else{
      this.booContinuar=false;
      this.strMensajeError += "Parece que se ha producido un error, asegurese de que tiene la cookie.Si no es así, vuelva a escanear el codigo QR.";
    }

    if(this.mesa!=undefined && this.mesa!=""){
      this.booContinuar2=true;
    }else{
      this.booContinuar2=false;
      this.strMensajeError+=" No se ha encontrado la mesa";
    }

    setTimeout(() => {
      if(this.booContinuar && this.booContinuar2){
        this.showError=false;
        this.showInfi=true;
        // sessionStorage.setItem(this.miCooKie, this.cookie);
        // sessionStorage.setItem(this.MesaId, this.mesa);

        this.getMesa();
      }else{
        this.showError=true;
        this.showInfi=false;
      }
    }, 500);
  }

  @HostListener('window:beforeunload', ['$event']) beforeUnloadHander(event) {
    sessionStorage.clear();
    // console.log("close")
  }

  removeCookies(){
    this.cookieService.delete(this.cookieNombre+this.idEstablecimiento,'',window.location.hostname);
  }


  Aceptar(){
    window.history.back();
  }

  getMesa(){
    this.miservicio.getMesas(this.cookie,this.mesa).subscribe(data=>{
      // console.log(data)

      if(data.miRespuesta.booOk){
        this.miservicio.getEstablecimiento(this.cookie).subscribe(datos=>{
          // console.log(datos)

          if(datos.miRespuesta.booOk){

            let IsCookieExists:boolean=this.cookieService.check(this.cookieNombre+this.idEstablecimiento);
            if(!IsCookieExists){
              this.midataCookie.coo=this.cookie;
              this.midataCookie.est=this.idEstablecimiento;
              this.midataCookie.mes=this.mesa;
              this.midataCookie.pro=this.idProducto;
              this.midataCookie.nombreMesa=data.miListMesa[0].strNombre;
              this.midataCookie.nombreZona=data.miListMesa[0].strZonaNombre;
              this.midataCookie.strColorCuerpo = "#"+datos.miListEstablecimiento[0].strColorCuerpo;
              this.midataCookie.strColorTextoCuerpo="#"+datos.miListEstablecimiento[0].strColorTextoCuerpo;
              this.midataCookie.strColorMenu="#"+datos.miListEstablecimiento[0].strColorMenu;
              this.midataCookie.strColorTextoMenu="#"+datos.miListEstablecimiento[0].strColorTextoMenu;

              this.cookieService.set(this.cookieNombre+this.idEstablecimiento,JSON.stringify(this.midataCookie),4,'',window.location.hostname);
            }else{
              this.removeCookies();

              //Vuelvo a crear la cookie
              this.midataCookie.coo=this.cookie;
              this.midataCookie.est=this.idEstablecimiento;
              this.midataCookie.mes=this.mesa;
              this.midataCookie.pro=this.idProducto;
              this.midataCookie.nombreMesa=data.miListMesa[0].strNombre;
              this.midataCookie.nombreZona=data.miListMesa[0].strZonaNombre;
              this.midataCookie.strColorCuerpo = "#"+datos.miListEstablecimiento[0].strColorCuerpo;
              this.midataCookie.strColorTextoCuerpo="#"+datos.miListEstablecimiento[0].strColorTextoCuerpo;
              this.midataCookie.strColorMenu="#"+datos.miListEstablecimiento[0].strColorMenu;
              this.midataCookie.strColorTextoMenu="#"+datos.miListEstablecimiento[0].strColorTextoMenu;

              this.cookieService.set(this.cookieNombre+this.idEstablecimiento,JSON.stringify(this.midataCookie),4,'',window.location.hostname);
            }

          }else{
            this.showError=true;
            this.showInfi=false;
            this.strMensajeError=data.miRespuesta.strMensaje;
          }

          setTimeout(() => {
            let extra: NavigationExtras = {
              queryParams: {
                'id':this.idEstablecimiento
              }
            }
            this.router.navigate(['/CartaOnline'], extra);
          }, 1000); 
          //Guardo los colores del establecimiento
          // sessionStorage.setItem(this.strColorMenu, "#"+datos[0].strColorMenu);
          // sessionStorage.setItem(this.strColorTextoMenu, "#"+datos[0].strColorTextoMenu);
          // sessionStorage.setItem(this.strColorCuerpo, "#"+datos[0].strColorCuerpo);
          // sessionStorage.setItem(this.strColorTextoCuerpo, "#"+datos[0].strColorTextoCuerpo);
  
          // this.router.navigate(['/CartaOnline']);
        })
      }else{
        // console.log(77)
        this.showError=true;
        this.showInfi=false;
        this.strMensajeError=data.miRespuesta.strMensaje;
      }

      //Guardo el nombre de la zona
      // sessionStorage.setItem(this.strNombreZona, data[0].strZonaNombre);

      //Guardo el nombre de la mesa
      // sessionStorage.setItem(this.strNombreMesa, data[0].strNombre);

      
    })
  }


  
}
