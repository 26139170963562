import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { Articulo } from 'src/app/models/articulo';

@Component({
  selector: 'app-ventana-num-articulos',
  templateUrl: './ventana-num-articulos.component.html',
  styleUrls: ['./ventana-num-articulos.component.scss']
})
export class VentanaNumArticulosComponent implements OnInit {

  cantidad=1;
  precioTotal=this.data.decTarifa;

  constructor(public thisDialogRef: MatDialogRef<MatDialog>, @Inject(MAT_DIALOG_DATA) public data: Articulo) { }

  ngOnInit() {
    this.thisDialogRef.updatePosition({ bottom: '0' });
    // console.log(this.data)
  }

  sum(){
    this.cantidad+=1;
    this.precioTotal=this.data.decTarifa*this.cantidad;
  }

  rest(){
    if(this.cantidad>1){
      this.cantidad-=1;
      this.precioTotal=this.data.decTarifa*this.cantidad;
    }
  }

  addPedido(){
    // console.log(this.data)
    this.thisDialogRef.close(this.cantidad);
  }

  close(){
    this.thisDialogRef.close();
  }
}
