import { Articulo } from './articulo';

export class Grupo {

    public intId?:number;
    public strNombre?:string;
    public strDescripcion?:string;
    public strActivo?:string;
    public strGrupoIdExt?:string;
    public strIdExtPadre?:string;
    public miListArticulo?: Articulo[] = [];
}
