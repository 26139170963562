export class Mesa {

    public intId?:number;
    public strNombre?:string;
    public intZonaId?:number;
    public intEstado?:number;
    public strMesaIdExt?:string;
    public strZonaNombre?:string;
    public strBloqueado?:string;
    public strBloqueadoClave?:string;
    public datBloqueadoInicio?:Date;
    public booMesaBloqueada?:boolean;

}
