export class Pedido {

    public intId?:number;
    public DatFechaRegistro?:Date;
    public intClienteId?:number;
    public strClienteNombre?:string;
    public strObservaciones?:string;
    public strCerrado?:string;
    public strDomicilio?:string;
    public strHoraEntrega?:string;
    public strDescargado?:string;
    public intFormaPagoId?:number;
    public intPedidoTipoId?:number;
    public intCamareroId?:number;
    public intMesaId?:number;
    public intZonaId?:number;
    public decBase?:number;
    public decCuotaIva?:number;
    public decCuotaReq?:number;
    public decCuotaIrpf?:number;
    public decImporte?:number;
    public strMesaVinculada?:string;
    public strMesaNombre?:string;
    public strCamareroIdExt?:string;
    public strMesaIdExt?:string;
    public datFechaDescargado?:Date;
    public strNotasDescargado?:string;
    public intEstadoMesaId?:number;
    public strEnviado?:string;
    public booModificaCabecera?:boolean;
    public strPedidoClave?:string;

}
