<div class="containerData">
    <div style="width: 100%;">
        <span class="close2" (click)="close()">&times;</span>
    </div>
    <br>
    <div class="tituloData">{{data.linea.strArticuloNombre}}</div>
    <div class="descripcionData"><b>{{data.linea.decPvp}}€</b></div>
    <br>
    <div class="descripcionData">{{data.linea.strDescripcion}}</div>
    <br>
    <div class="cantidadData"> 
        <!-- <div class="menos" [disabled]="true">-</div> -->
        <div class="menos" (click)="rest()">-</div>
        {{data.linea.decCantidad}} 
        <!-- <div class="mas">+</div> -->
        <div class="mas" (click)="sum()">+</div>
    </div>
    <br>
    <p style="color: tomato; text-align: center; font-weight: 600;" (click)="eliminarLinea()">
        Eliminar del pedido
    </p>
    <!-- <br> -->
    <div class="btnPedidoData">
        <button mat-raised-button color="warn" style="width: 100%;" (click)="addPedido()">Añadir al pedido {{precioTotal | number}} €</button>
    </div> 
</div>

