import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FooterComponent } from './components/footer/footer.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { LayoutComponent } from './components/layout/layout.component';
import { DestacadosComponent } from './components/destacados/destacados.component';
import { ArticulosComponent } from './components/articulos/articulos.component';
import { DetalleComponent } from './components/detalle/detalle.component';
import { InicioComponent } from './components/inicio/inicio.component';
import { PoliticaCookiesComponent } from './components/politica-cookies/politica-cookies.component';
import { ResumenComponent } from './components/resumen/resumen.component';
import { MensajeErrorComponent } from './components/mensaje-error/mensaje-error.component';
import { NombreClienteComponent } from './components/nombre-cliente/nombre-cliente.component';
import { MenuListItemComponent } from './components/menu-list-item/menu-list-item.component';
import { ConfiguracionesComponent } from './components/configuraciones/configuraciones.component';
import { VentanaEmergenteComponent } from './ventana-emergente/ventana-emergente.component';
import { VentanaNumArticulosComponent } from './components/ventana-num-articulos/ventana-num-articulos.component';
import { VentanaNumLineasComponent } from './components/ventana-num-lineas/ventana-num-lineas.component';

const routes: Routes = [
  /************ componentes que se pueden cargar sin loguearse ***********/
  { path: 'footer', component: FooterComponent },

  { path: 'CartaOnline', component: SidenavComponent },
  { path: 'layout', component: LayoutComponent },
  { path: 'destacados', component: DestacadosComponent },
  { path: 'articulos', component: ArticulosComponent },
  { path: 'detalle', component: DetalleComponent },
  { path: 'bienvenido', component: InicioComponent },
  { path: 'politica-cookies', component: PoliticaCookiesComponent },
  { path: 'resumen', component: ResumenComponent },
  { path: 'mensaje-error', component: MensajeErrorComponent },
  { path: 'nombre-cliente', component: NombreClienteComponent },
  { path: 'menu-item', component: MenuListItemComponent},
  { path: 'ventana-emergente', component: VentanaEmergenteComponent},
  { path: 'ventana-num-articulos', component: VentanaNumArticulosComponent},
  { path: 'ventana-num-lineas', component: VentanaNumLineasComponent},
  /************************* Estos son los componentes visibles para el administrador ************************/

  // { path: 'configuraciones', component: ConfiguracionesComponent, canActivate: [ AuthAdminGuard ] },

  /************************* Estos son rutas por defecto ************************/

  { path: '', redirectTo: '/bienvenido', pathMatch: 'full' },
  { path: '**', redirectTo: '/bienvenido'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
