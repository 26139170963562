import { Component, OnInit } from '@angular/core';
import { PoliticaCookiesComponent } from '../politica-cookies/politica-cookies.component';
import { Establecimiento } from 'src/app/models/establecimiento';
import { StorageService } from 'src/app/services/storage.service';
import { CartaOnlineService } from 'src/app/services/carta-online.service';
import { MatDialog } from '@angular/material';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { DataCookie } from 'src/app/models/data-cookie';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  //NO PUEDO COGER EL NOMBRE DE LA SESION PORQUE EN EL LOGIN NO HAY SESION INICIADA
  //nombreApp:string=this.storageService.getCurrentSession().strNombre;
  nombreApp:string;
  tituloApp:string;

  cookieNombreNavegador = environment.strCookieNavegador;
  cookieNombre = environment.strCookie;
  aceptCookies:boolean=true;
  // IsCookieExists:boolean=this.cookieService.check(this.cookieNombreNavegador);
  miEstablecimiento:Establecimiento=new Establecimiento();
  midataCookie:DataCookie=new DataCookie();
  idEstablecimiento:string="";

  colorTextoCuerpo:string;
  // strColorTextoCuerpo:string=environment.strColorTextoCuerpo;

  constructor(private storageService: StorageService,private miservicio:CartaOnlineService,private dialog: MatDialog,private route: ActivatedRoute,
    private cookieService: CookieService) {
      this.route.queryParams.subscribe(params => {
        this.idEstablecimiento=params["id"];
      });
     }

  ngOnInit() {
    
    //console.log(sessionStorage.getItem('strCookie'))
    this.midataCookie=JSON.parse(this.cookieService.get(this.cookieNombre+this.idEstablecimiento));
    setTimeout(()=>{
      // this.colorCuerpo=this.midataCookie.strColorCuerpo;
      this.colorTextoCuerpo=this.midataCookie.strColorTextoCuerpo;
      // this.colorMenu=this.midataCookie.strColorMenu;
      // this.colorTextoMenu=this.midataCookie.strColorTextoMenu;
      // this.strMesa=this.midataCookie.nombreMesa;
      // this.strZona=this.midataCookie.nombreZona;
      this.getNombreApp();
    }, 500)

    let IsCookieExists:boolean=this.cookieService.check(this.cookieNombreNavegador+this.idEstablecimiento);
    if(IsCookieExists){
      this.aceptCookies=false;
    } 
    // this.getNombreApp();
    // if(this.IsCookieExists===true){
    //   this.aceptCookies=false;
    // }
    // this.colorTextoCuerpo=sessionStorage.getItem(this.strColorTextoCuerpo);
    //console.log(window.location);
    //this.getDominio();
  }

  getNombreApp(){
    this.miservicio.getEstablecimiento(this.midataCookie.coo).subscribe(datos => { 
      // console.log(datos)
      if(datos.miRespuesta.booOk){
        this.miEstablecimiento=datos.miListEstablecimiento[0];
        // console.log(this.miEstablecimiento)
  
        if(this.miEstablecimiento.strTelefono1!=null && this.miEstablecimiento.strTelefono1!=""){
          this.miEstablecimiento.strTelefono1=" - " + this.miEstablecimiento.strTelefono1;
        }
        if(this.miEstablecimiento.strTelefono2!=null && this.miEstablecimiento.strTelefono2!=""){
          this.miEstablecimiento.strTelefono2=" - " + this.miEstablecimiento.strTelefono2;
        }
        if(this.miEstablecimiento.strDireccion!=null && this.miEstablecimiento.strDireccion!=""){
          this.miEstablecimiento.strDireccion=" - " + this.miEstablecimiento.strDireccion;
        }
        if(this.miEstablecimiento.strPoblacion!=null && this.miEstablecimiento.strPoblacion!=""){
          this.miEstablecimiento.strPoblacion=" - " + this.miEstablecimiento.strPoblacion;
        }
        if(this.miEstablecimiento.strCp!=null && this.miEstablecimiento.strCp!=""){
          this.miEstablecimiento.strCp=" - " + this.miEstablecimiento.strCp;
        }
        if(this.miEstablecimiento.strProvincia!=null && this.miEstablecimiento.strProvincia!=""){
          this.miEstablecimiento.strProvincia=" - " + this.miEstablecimiento.strProvincia;
        }
      }
      

    })
  }

  cancelar(){
    this.aceptCookies=false;
  }

  aceptaCookies(){
    this.cookieService.set( this.cookieNombreNavegador+this.idEstablecimiento, 'CartaOnline',365,'',window.location.hostname );
    this.aceptCookies=false;
  }

  cookies(){
    let dialogRef = this.dialog.open(PoliticaCookiesComponent, {
      width:'600px',
      data:this.midataCookie
    });
    dialogRef.afterClosed().subscribe(result =>{
      if(result){
        this.cookieService.set(this.cookieNombreNavegador+this.idEstablecimiento,'CartaOnline',365,'',window.location.hostname);
        this.aceptCookies=false;
      }
    })
  }


}