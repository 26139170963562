import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  mensaje:any;
  destacado:any;
  @Output() onPulsar2= new EventEmitter<number>();
  @Output() onPulsarDes2= new EventEmitter<number>();

  constructor() { }

  ngOnInit() {
  }

  onPadre1(e:EventEmitter<number>){
    this.mensaje=e
    //console.log(this.mensaje);
    this.onPulsar2.emit(this.mensaje);   
  }

  onPadreDes(e:EventEmitter<string>){
    this.destacado=e
    //console.log(this.destacado);
    this.onPulsarDes2.emit(this.destacado);   
  }

}
