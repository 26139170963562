<div style="width: 100%;">
  <span class="close2" (click)="close()">&times;</span>
</div>
<ul>
    <li>
      <span>Pin de desbloqueo.</span>
    </li>
    <br>
    <li>
      <!-- <span>{{mensaje}}</span> -->
      <input matInput placeholder="pin" [(ngModel)]="nombre" required>
    </li>
    <br>
    <li>
      <nav *ngIf="nombreVacio">* Campo obligatorio</nav>
    </li>
    <br>
    <li><button (click)="guardar()" mat-raised-button color="warn">Aceptar</button></li>
</ul>
  