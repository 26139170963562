import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { PedidoLinea } from 'src/app/models/pedido-linea';
import { CartaOnlineService } from 'src/app/services/carta-online.service';
import { DataCookie } from 'src/app/models/data-cookie';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-ventana-num-lineas',
  templateUrl: './ventana-num-lineas.component.html',
  styleUrls: ['./ventana-num-lineas.component.scss']
})
export class VentanaNumLineasComponent implements OnInit {

  precioTotal=this.data.linea.decPvp*this.data.linea.decCantidad;
  listLineasEliminar:PedidoLinea[]=[];

  constructor(public thisDialogRef: MatDialogRef<MatDialog>, @Inject(MAT_DIALOG_DATA) public data: any, private miservicio:CartaOnlineService) { }

  ngOnInit() {
    // console.log(this.data)
    this.thisDialogRef.updatePosition({ bottom: '0' });
    this.listLineasEliminar.push(this.data.linea)
  }

  sum(){
    this.data.linea.decCantidad+=1;
    this.precioTotal=this.data.linea.decPvp*this.data.linea.decCantidad;
    // console.log(this.data.decPvp + " " + this.data.decCantidad)
    // this.precioTotal.toFixed(2);
    // console.log(this.precioTotal.toFixed(2))
  }

  rest(){
    if(this.data.linea.decCantidad>1){
      this.data.linea.decCantidad-=1;
      this.precioTotal=this.data.linea.decPvp*this.data.linea.decCantidad;
      // this.precioTotal.toFixed(2);
    }
  }

  eliminarLinea(){
    this.miservicio.deleteLineasPedido(this.data.coo,this.listLineasEliminar,this.data.mesa).subscribe(datos=>{
      // console.log(datos)
      this.thisDialogRef.close(datos);
      // if(datos.miListPedidoLinea.length>0){
      //   this.listPedidoLineas=datos.miListPedidoLinea;
      //   this.miPedido=datos.miListPedidos[0];
      //   this.totalArticulos=datos.miListPedidoLinea[datos.miListPedidoLinea.length-1].decTotalArticulos;
      // }
    })
  }

  addPedido(){
    // console.log(this.data)
    // this.thisDialogRef.close(this.data.decCantidad);

    this.miservicio.putLineaPedido(this.data.coo,this.data.linea,this.data.mesa).subscribe(datos=>{
      // console.log(datos)
      this.thisDialogRef.close(datos);
      // if(datos.miRespuesta.booOk){
      //   this.listPedidoLineas.splice(0, this.listPedidoLineas.length);
      //   this.listPedidoLineas=datos.miListPedidoLinea;
      //   this.totalArticulos=datos.miListPedidoLinea[datos.miListPedidoLinea.length-1].decTotalArticulos;
      // }
    })
  }

  close(){
    this.thisDialogRef.close();
  }

}
