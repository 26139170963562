<div class="example-container menuMovil">


    <mat-drawer-container autosize>
      <mat-drawer #drawer class="example-sidenav" mode="over" [style.background-color]=colorMenu [style.color]=colorTextoMenu>
    
        <div class="no-v-scroll">
    
          <mat-nav-list>
    
            <mat-list-item style="height: 100%;">
              <span class="full-width miMenu" style="font-size: 13px; max-width: 160px; font-weight: 500; color:grey"><i style="vertical-align: super; font-weight: 700;">...</i> {{strZona}}</span>
            </mat-list-item>
    
            <mat-list-item style="height: 100%;" (click)="getDestacado()">
              <span class="full-width miMenu" style="font-size: 18px; max-width: 160px; font-weight: 500;" [style.color]=colorTextoMenu>{{strMesa}}</span>
              <mat-icon matBadge="{{totalArticulos}}" matBadgeColor="warn" class="carrito2" (click)="resumen()" *ngIf="muestraTotalArticulos" [style.color]=colorTextoMenu>shopping_cart</mat-icon>
            </mat-list-item>
    
            <mat-list-item style="height: 100%;">
              <span class="full-width miMenu" style="font-size: 13px; max-width: 160px; font-weight: 500; color:grey"><i style="vertical-align: super; font-weight: 700;">...</i> Recomendaciones</span>
            </mat-list-item>
    
            <mat-list-item style="height: 100%;" (click)="getDestacado()">
              <span class="full-width miMenu" style="font-size: 16px; max-width: 160px; font-weight: 500;" [style.color]=colorTextoMenu>Destacados</span>
            </mat-list-item>
    
            <mat-list-item style="height: 100%;">
              <span class="full-width miMenu" style="font-size: 13px; max-width: 160px; font-weight: 500; color:grey"><i style="vertical-align: super; font-weight: 700;">...</i> Grupos</span>
            </mat-list-item>
    
            <mat-list-item style="height: 100%;" *ngFor="let grupo of listaGrupos" value="grupo.intId" (click)="getGrupo(grupo)">
              <span class="full-width miMenu" style="font-size: 16px; max-width: 160px; font-weight: 300;" [style.color]=colorTextoMenu>{{grupo.strNombre}}</span>
            </mat-list-item>
    
            <br><br>
          </mat-nav-list>
        </div>
    
      </mat-drawer>
    
      <div class="example-sidenav-content">
    
        <div [style.background-color]=colorMenu [style.color]=colorTextoMenu class="cabecera">
    
          <button mat-icon-button id="cierraMenu" (click)="drawer.toggle()" ><mat-icon>menu</mat-icon></button>
    
          <div class="datosEstablecimiento">
            <img src="data:image/gif;base64,{{miEstablecimiento.strLogo}}" (click)="verDestacados()">
            <div class="establecimiento">
              {{miEstablecimiento.strNombre}} 
            </div>
            <mat-icon matBadge="{{totalArticulos}}" matBadgeColor="warn" class="carrito" *ngIf="muestraTotalArticulos" (click)="resumen()">shopping_cart</mat-icon>
          </div>
        </div>
    
        <section [style.background-color]=colorCuerpo [style.color]=colorTextoCuerpo>
          
          <div [style.background-color]=colorCuerpo>
            {{nombreGrupo}}
          </div>
          <br>
    
          <nav class="errorDatos" *ngIf="sinDatos">
            <span>No hay datos para mostrar</span>
          </nav>
    
          <section *ngIf="myLoader"  class="miLoader">
    
            <svg viewBox="-2000 -1000 4000 2000">
              <path id="inf" d="M354-354A500 500 0 1 1 354 354L-354-354A500 500 0 1 0-354 354z">
              </path>
              <use xlink:href="#inf" stroke-dasharray="1570 5143" stroke-dashoffset="6713px">
              </use>
            </svg>
          </section>
    
          <table class="listArticulos" *ngFor="let articulo of listArticulos2">
            <tr class="separacion">
              <td class="imagenesMuestra" (click)="verArticulo(articulo)">
                  <img src="data:image/gif;base64,{{articulo.strImagen1MiniBase64}}" style="vertical-align: middle;"  *ngIf="articulo.booMuestraImagen">
              </td>
              <td class="textoMuestra">
                <table>
                  <tr (click)="verArticulo(articulo)">
                    <td class="nombrePlato">{{articulo.strNombreRecortada}}</td>
                  </tr>
                  <tr (click)="verArticulo(articulo)">
                    <td> <span style="font-weight: 600;">Descripción: </span>{{articulo.strDescripcionRecortada}}</td>
                  </tr>
                  <tr (click)="verArticulo(articulo)">
                    <td class="precioArticulo">
                      <span style="font-weight: 600;">Precio: </span>
                      {{articulo.decTarifa}} €
                      <!--******** OCULTO EL BOTON PARA HACER UN PEDIDO *******-->
                      <!-- <img src="../../assets/add-24.png" width="30" height="30" (click)="mas(articulo)" class="btnAdd"> -->
                    </td>
                  </tr>
                  <tr (click)="verArticulo(articulo)">
                    <td>
                      <!-- <span style="font-weight: 600;">Alérgenos: </span> {{articulo.strAlergenos}} -->
                      <img *ngFor="let alergeno of articulo.miListArticuloAlergeno" src="data:image/gif;base64,{{alergeno.strImagen1Base64}}" class="iconosAlergenos">
                      <!-- <img src="../../assets/ico-pescado.png" class="iconosAlergenos"> -->
                    </td>
                  </tr>
                  <tr><td><br></td></tr>
                </table>
              </td>
            </tr>
          </table>
          <br><br>
        </section>
      </div>
    
      <app-footer></app-footer>
    </mat-drawer-container>
    
    </div>
    
    <!--**********************************************************************************************************************************************************-->
    
    
    <div class="example-container" class="menuGrande">
      <mat-toolbar [style.background-color]=colorMenu [style.color]=colorTextoMenu class="example-toolbar">
        <div [style.background-color]=colorMenu [style.color]=colorTextoMenu class="cabecera">
    
          <button mat-icon-button id="cierraMenu" (click)="snav.toggle()" ><mat-icon>menu</mat-icon></button>
    
          <div class="datosEstablecimiento">
            <img src="data:image/gif;base64,{{miEstablecimiento.strLogo}}" (click)="verDestacados()">
            <!-- <div class="establecimiento">
              {{miEstablecimiento.strNombre}} 
            </div> -->
            <mat-icon matBadge="{{totalArticulos}}" matBadgeColor="warn" class="carrito" *ngIf="muestraTotalArticulos" (click)="resumen()">shopping_cart</mat-icon>
          </div>
        </div>
      </mat-toolbar>
    
      <mat-sidenav-container>
        
        <mat-sidenav #snav class="example-sidenav" mode="side" opened="true" [style.background-color]=colorMenu>
    
          <div class="no-v-scroll">
    
            <mat-nav-list>
    
              <mat-list-item style="height: 100%;">
                <span class="full-width miMenu" style="font-size: 13px; max-width: 160px; font-weight: 500; color:grey"><i style="vertical-align: super; font-weight: 700;">...</i> {{strZona}}</span>
              </mat-list-item>
    
              <mat-list-item style="height: 100%; width: 100%;" (click)="getDestacado()">
                <span class="full-width miMenu" style="font-size: 18px; max-width: 160px; font-weight: 500;" [style.color]=colorTextoMenu>{{strMesa}}</span>&nbsp;&nbsp;
                <mat-icon matBadge="{{totalArticulos}}" matBadgeColor="warn" class="carrito2" (click)="resumen()" *ngIf="muestraTotalArticulos" [style.color]=colorTextoMenu>shopping_cart</mat-icon>
              </mat-list-item>
    
              <mat-list-item style="height: 100%;">
                <span class="full-width miMenu" style="font-size: 13px; max-width: 160px; font-weight: 500; color:grey"><i style="vertical-align: super; font-weight: 700;">...</i> Recomendaciones</span>
              </mat-list-item>
    
              <mat-list-item style="height: 100%;" (click)="getDestacado()">
                <span class="full-width miMenu" style="font-size: 16px; max-width: 160px; font-weight: 500;" [style.color]=colorTextoMenu>Destacados</span>
              </mat-list-item>
    
              <mat-list-item style="height: 100%;">
                <span class="full-width miMenu" style="font-size: 13px; max-width: 160px; font-weight: 500; color:grey"><i style="vertical-align: super; font-weight: 700;">...</i> Grupos</span>
              </mat-list-item>
    
              <mat-list-item style="height: 100%;" *ngFor="let grupo of listaGrupos" value="grupo.intId" (click)="getGrupo(grupo)">
                <span class="full-width miMenu" style="font-size: 16px; max-width: 160px; font-weight: 300;" [style.color]=colorTextoMenu>{{grupo.strNombre}}</span>
              </mat-list-item>

              <mat-list-item style="height: 100%;">
                <span class="full-width miMenu" style="font-size: 13px; max-width: 160px; font-weight: 500; color:grey"><i style="vertical-align: super; font-weight: 700;">...</i> Menús</span>
              </mat-list-item>

              <mat-list-item style="height: 100%;" *ngFor="let grupo of miListGruposMenu" value="grupo.intId" (click)="getGrupo(grupo)">
                <span class="full-width miMenu" style="font-size: 16px; max-width: 160px; font-weight: 300;" [style.color]=colorTextoMenu>{{grupo.strNombre}}</span>
              </mat-list-item>
    
              <br><br><br><br><br>
            </mat-nav-list>
          </div>
                
        </mat-sidenav>
    
        <mat-sidenav-content>
          <section [style.background-color]=colorCuerpo [style.color]=colorTextoCuerpo class="contenidoCarta">
          
            <div [style.background-color]=colorCuerpo class="txtDesstacado">
              {{nombreGrupo}} <span *ngIf="btnMenu">{{miArticuloConPrecio.decTarifa}} €</span>
            </div>
            
            <div class="txtDescripcionMenu" *ngIf="btnMenu">
              {{miArticuloConPrecio.strDescripcion}}
            </div>

            <br>
            
            <nav class="errorDatos" *ngIf="sinDatos">
              <span>No hay datos para mostrar</span>
            </nav>
      
            <section *ngIf="myLoader"  class="miLoader">
      
              <svg viewBox="-2000 -1000 4000 2000">
                <path id="inf" d="M354-354A500 500 0 1 1 354 354L-354-354A500 500 0 1 0-354 354z">
                </path>
                <use xlink:href="#inf" stroke-dasharray="1570 5143" stroke-dashoffset="6713px">
                </use>
              </svg>
            </section>
      
            <table class="listArticulos" *ngFor="let articulo of listArticulos2">
              <tr class="separacion">
                <td class="imagenesMuestra" (click)="verArticulo(articulo)" *ngIf="articulo.booMuestraImagen">
                    <img src="data:image/gif;base64,{{articulo.strImagen1MiniBase64}}" style="vertical-align: middle;">
                </td>
                <td class="textoMuestra">
                  <table>
                    <tr (click)="verArticulo(articulo)">
                      <td class="nombrePlato">{{articulo.strNombreRecortada}}</td>
                    </tr>
                    <tr (click)="verArticulo(articulo)">
                      <td> <span style="font-weight: 600;">Descripción: </span>{{articulo.strDescripcionRecortada}}</td>
                    </tr>
                    <tr (click)="verArticulo(articulo)">
                      <td class="precioArticulo">
                        <span style="font-weight: 600;">Precio: </span>
                        {{articulo.decTarifa}} €

                        <!--******** OCULTO EL BOTON PARA HACER UN PEDIDO *******-->
                        <!-- <img src="../../../assets/add-24.png" width="30" height="30" (click)="mas(articulo)" class="btnAdd"> -->
                      </td>
                    </tr>
                    <tr (click)="verArticulo(articulo)">
                      <td>
                        <!-- <span style="font-weight: 600;">Alérgenos: </span> {{articulo.strAlergenos}} -->
                        <img *ngFor="let alergeno of articulo.miListArticuloAlergeno" src="data:image/gif;base64,{{alergeno.strImagen1Base64}}" class="iconosAlergenos">
                      </td>
                    </tr>
                    <tr>
                      <!-- <td><button mat-raised-button color="warn" (click)="mas(articulo)" *ngIf="!miMesa.booMesaBloqueada"><mat-icon>add_shopping_cart</mat-icon> Añadir al pedido</button></td> -->
                      <td><button mat-raised-button color="warn" (click)="mas(articulo)" *ngIf="miEstablecimiento.strClave === '' ? false : true"><mat-icon>add_shopping_cart</mat-icon> Añadir al pedido</button></td>
                    </tr>
                    <tr><td><br></td></tr>
                  </table>
                </td>
              </tr>
            </table>

            <table class="listArticulos itemsMenu" *ngFor="let grupo of miListGrupoMenu" >
              <tr class="separacion" *ngIf="btnMenu">
                <td class="textoMuestra">
                  <table>
                    <tr>
                      <td class="nombrePlato">{{grupo.strNombre}}</td>
                    </tr>
                    <tr><td><br></td></tr>
                    <!-- <tr *ngFor="let art of grupo.miListArticulo;">
                      <td>
                        <mat-radio-button [name]="grupo.intId" [value]="art.intId" (change)="radioChange($event)" 
                        style="background-color: red; max-width: 120px !important;">
                          <span style="background-color: royalblue; max-width: 120px !important;">{{art.strNombre}}</span>
                        </mat-radio-button>
                      </td>
                    </tr> -->
                    <tr *ngFor="let art of grupo.miListArticulo;">
                      <td>
                        <span>
                          <!-- <input type="radio" [name]="grupo.intId" id="{{art.intId}}" [value]="art.intId" (change)="radioChange($event)"> 
                          <label for="{{art.intId}}">{{art.strNombre}}</label> -->
                          <label>
                            <input type="radio" [name]="grupo.intId" id="{{art.intId}}" [value]="art.intId" (change)="radioChange($event)">
                            {{art.strNombre}}
                          </label>
                        </span>
                      </td>
                    </tr>
                    <!-- <tr>
                      <td>
                        <mat-radio-group [name]="grupo.intId" [(ngModel)]="grupo.intId">
                        <mat-radio-button *ngFor="let art of grupo.miListArticulo" class="example-radio-button" [name]="grupo.intId" [value]="art.intId" (change)="radioChange($event)" >
                          {{art.strNombre}}
                        </mat-radio-button>
                        </mat-radio-group>
                      </td>
                    </tr> -->
                    <tr><td><br></td></tr>
                  </table>
                </td>
              </tr>
            </table>
            
            <div *ngIf="btnMenu" class="txtDesstacado">
              <!-- <button mat-raised-button color="warn" (click)="pedirMenu()" *ngIf="!miMesa.booMesaBloqueada"><mat-icon>add_shopping_cart</mat-icon> Añadir al pedido</button> -->
              <!-- <td><button mat-raised-button color="warn" (click)="pedirMenu()" *ngIf="miEstablecimiento.strClave === '' ? false : true"><mat-icon>add_shopping_cart</mat-icon> Añadir al pedido</button></td> -->
              <button mat-raised-button color="warn" (click)="pedirMenu()" *ngIf="miEstablecimiento.strClave === '' ? false : true"><mat-icon>add_shopping_cart</mat-icon> Añadir al pedido</button>
            </div>
            

            <br><br><br><br>
          </section>
        </mat-sidenav-content>
      </mat-sidenav-container>
    </div>
    
    <app-footer class="menuGrande"></app-footer>