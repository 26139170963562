
<div>
    <svg viewBox="-2000 -1000 4000 2000" *ngIf="showInfi">
      <path id="inf" d="M354-354A500 500 0 1 1 354 354L-354-354A500 500 0 1 0-354 354z">
      </path>
      <use xlink:href="#inf" stroke-dasharray="1570 5143" stroke-dashoffset="6713px">
      </use>
    </svg>

    <div style="position: relative;" *ngIf="showError">
      <div style="width: 80%; height: 30%; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); font-size: 18px; font-weight: 600; color:red">
        {{strMensajeError}}
        <br><br>
          <button mat-raised-button color="warn" (click)="Aceptar()" style="font-weight: 600;">Reintentar</button>
      </div>
    </div>
</div>
