import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-nombre-cliente',
  templateUrl: './nombre-cliente.component.html',
  styleUrls: ['./nombre-cliente.component.scss']
})
export class NombreClienteComponent implements OnInit {

  nombre:string;
  nombreVacio:boolean=false;

  constructor(public thisDialogRef: MatDialogRef<MatDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {
   }

  ngOnInit() {
  }

  guardar(){
    if(this.nombre!=undefined){
      this.nombreVacio=false;
      this.thisDialogRef.close(this.nombre);
    }else{
      this.nombreVacio=true;
    }
  }

  close(){
    this.thisDialogRef.close();
  }

}
