import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { Articulo } from 'src/app/models/articulo';
import { Establecimiento } from 'src/app/models/establecimiento';
import { environment } from 'src/environments/environment';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { CartaOnlineService } from 'src/app/services/carta-online.service';
import { DataCookie } from 'src/app/models/data-cookie';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-detalle',
  templateUrl: './detalle.component.html',
  styleUrls: ['./detalle.component.scss']
})
export class DetalleComponent implements OnInit {

  //cookie="f453a3b1-21f4-4172-aa6c-c7d8cb2edce8-1-2018101815266406";
  //mesa=9;
  grupoId:any;

  listArticulos2:Articulo[]=[];
  // listArticulos2: Observable<Articulo[]>;
  listEstablecimiento:Establecimiento[]=[];

  colorCuerpo:string;
  colorMenu:string;
  colorTextoCuerpo:string;
  colorTextoMenu:string;
  myLoader:boolean=false;

  midataCookie:DataCookie=new DataCookie();
  cookieNombre:string=environment.strCookie;

  // strColorMenu:string=environment.strColorMenu;
  // strColorTextoMenu:string=environment.strColorTextoMenu;
  // strColorCuerpo:string=environment.strColorCuerpo;
  // strColorTextoCuerpo:string=environment.strColorTextoCuerpo;

  public myListImg:ListImg[] = [];
  @ViewChild("imgMuestra", {static: false}) imgField:ElementRef;

  constructor(public thisDialogRef: MatDialogRef<MatDialog>, @Inject(MAT_DIALOG_DATA) public data: any,private cookieService: CookieService,
  private miservicio:CartaOnlineService) { }

  ngOnInit() {
    

    this.myLoader=true;

    this.midataCookie=JSON.parse(this.cookieService.get(this.cookieNombre+this.data.est))
    // this.cargarVariablesCookies();
    setTimeout(()=>{
      this.colorCuerpo=this.midataCookie.strColorCuerpo;
      this.colorTextoCuerpo=this.midataCookie.strColorTextoCuerpo;
      this.colorMenu=this.midataCookie.strColorMenu;
      this.colorTextoMenu=this.midataCookie.strColorTextoMenu;
      this.listarArticulos();
      // this.datosEstablecimiento();
    }, 500)
  }

  // datosEstablecimiento(){
  //   // this.miservicio.getEstablecimiento(this.data.coo).subscribe(datos=>{     
  //   //   // console.log(datos);
  //   //   this.listEstablecimiento=datos;
  //   //   //console.log(this.listEstablecimiento);
  //   //   this.colorCuerpo="#"+this.listEstablecimiento[0].strColorCuerpo;
  //   //   this.colorTextoCuerpo="#"+this.listEstablecimiento[0].strColorTextoCuerpo;
  //   //   this.colorMenu="#"+this.listEstablecimiento[0].strColorMenu;
  //   //   this.colorTextoMenu="#"+this.listEstablecimiento[0].strColorTextoMenu;
  //   // })

  //   this.colorCuerpo=sessionStorage.getItem(this.strColorCuerpo);
  //   this.colorMenu=sessionStorage.getItem(this.strColorMenu);
  //   this.colorTextoCuerpo=sessionStorage.getItem(this.strColorTextoCuerpo);
  //   this.colorTextoMenu=sessionStorage.getItem(this.strColorTextoMenu);
  // }

  changeImg(e){
    // console.log(e.target.id)
    // for (var i = 0; i < this.myListImg.length; i++) {
    //   if (this.myListImg[i].id === e.target.id) {
    //       this.imgField.nativeElement.src=this.myListImg[i].src;
    //   }
    // }
    // console.log(this.myListImg)
    // console.log(this.myListImg[e.target.id-1])

    this.imgField.nativeElement.src=this.myListImg[e.target.id].src;
  }

  listarArticulos(){
    this.miservicio.getArticulos(this.data.coo,this.data.mes,this.data.intId,"",0,"").subscribe(datos=>{
      // console.log(datos);
      if(datos.miRespuesta.booOk){
        this.listArticulos2=datos.miListArticulo;
        // console.log(datos.miListArticulo[0].strImagen1Base64)
        // this.imgField.nativeElement.src="data:image/gif;base64," + datos.miListArticulo[0].strImagen1Base64;
        this.myListImg.push(
          {id: "0", src: 'data:image/gif;base64,' + datos.miListArticulo[0].strImagen1Base64},
          {id: "1", src: 'data:image/gif;base64,' + datos.miListArticulo[0].strImagen2Base64},
          {id: "2", src: 'data:image/gif;base64,' + datos.miListArticulo[0].strImagen3Base64},
          {id: "3", src: 'data:image/gif;base64,' + datos.miListArticulo[0].strImagen4Base64},
        );
      }
        
      this.myLoader=false;
      // console.log(this.listArticulos2);
    })
  }

}

export interface ListImg{
  id:any;
  src:string;
}