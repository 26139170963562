import { Component, OnInit, EventEmitter } from '@angular/core';
import { CartaOnlineService } from 'src/app/services/carta-online.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-destacados',
  templateUrl: './destacados.component.html',
  styleUrls: ['./destacados.component.scss']
})
export class DestacadosComponent implements OnInit {

  //cookie:string;
  cookie="52ef0e28-4ddf-4beb-b1fa-1a2c1bc15066-1-20181010123454411";
  //mesa:any;
  mesa=9;
  grupoId:any;
  destacado:any;

  constructor(private miservicio:CartaOnlineService,private route: ActivatedRoute) { 
    this.route.queryParams.subscribe(params => {
      //this.cookie=params["coo"];
      //this.mesa=params["mes"];
    });
  }

  ngOnInit() {
    //this.datosEstablecimiento();
  }  

  onPadre2(a:EventEmitter<number>){
    //console.log(e)
    this.grupoId=a;
    //console.log(this.grupoId)
    this.listArticulos();
  }

  onPadreDes2(a:EventEmitter<string>){
    //console.log(e)
    this.destacado=a;
    //console.log(this.destacado)
    this.listArticulosDestacados();
  }

  listArticulosDestacados(){
    this.miservicio.getArticulos(this.cookie,this.mesa,0,"S",0,"").subscribe(datos=>{
      console.log(datos);
    })
  }

  listArticulos(){
    this.miservicio.getArticulos(this.cookie,this.mesa,0,"",this.grupoId,"").subscribe(datos=>{
      console.log(datos);
    })
  }

  listGrupos(){
    this.miservicio.getGrupos(this.cookie).subscribe(datos=>{
      console.log(datos);
    })
  }

}
