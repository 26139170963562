<section *ngIf="myLoader"  class="miLoader">
    <svg viewBox="-2000 -1000 4000 2000">
      <path id="inf" d="M354-354A500 500 0 1 1 354 354L-354-354A500 500 0 1 0-354 354z">
      </path>
      <use xlink:href="#inf" stroke-dasharray="1570 5143" stroke-dashoffset="6713px">
      </use>
    </svg>
  </section>
  
  <!-- <table *ngFor="let articulo of listArticulos2 |async" [style.background-color]=colorCuerpo [style.color]=colorTextoCuerpo> -->
  <table *ngFor="let articulo of listArticulos2" [style.background-color]=colorCuerpo [style.color]=colorTextoCuerpo>
    <!--<caption [style.background-color]=colorCuerpo [style.color]=colorTextoCuerpo>-->
      <tr>
        <th [style.color]=colorTextoCuerpo>
          {{articulo.strNombre}}        
        </th>
      </tr>
    <!--</caption>-->
    <!-- <br> -->
    <tbody>
      <tr>
        <td class="imagenesMuestra" *ngIf="articulo.booMuestraImagen">
            <img src="data:image/gif;base64,{{articulo.strImagen1Base64}}" #imgMuestra id="imgMuestra">
            <!-- <img [src]="data:image/gif;base64, myListImg[1]" #imgMuestra id="imgMuestra"> -->
        </td>
      </tr>
      <tr>
        <td *ngIf="articulo.booMuestraImagen" class="carrousel">
          <ul>
            <li tabindex="0"><img src="data:image/gif;base64,{{articulo.strImagen1Base64}}" (click)="changeImg($event)" id="0"></li>
            <li tabindex="1"><img src="data:image/gif;base64,{{articulo.strImagen2Base64}}" (click)="changeImg($event)" id="1"></li>
            <li tabindex="2"><img src="data:image/gif;base64,{{articulo.strImagen3Base64}}" (click)="changeImg($event)" id="2"></li>
            <li tabindex="3"><img src="data:image/gif;base64,{{articulo.strImagen4Base64}}" (click)="changeImg($event)" id="3"></li>
          </ul>
        </td>
      </tr>
      <tr>
        <td class="texto">
          <span style="font-weight: 600;">Descripción: </span>{{articulo.strDescripcion}}
        </td>
      </tr>
      <tr>
        <td class="texto">
            <span style="font-weight: 600;">Precio: </span>
          {{articulo.decTarifa}} €
        </td>
      </tr>
      <tr>
        <td class="texto" style="text-align: center;">
            <!-- <span style="font-weight: 600;">Alérgenos: </span>{{articulo.strAlergenos}} -->
            <img *ngFor="let alergeno of articulo.miListArticuloAlergeno" src="data:image/gif;base64,{{alergeno.strImagen1Base64}}" class="iconosAlergenos">
        </td>
      </tr>
    </tbody>
  </table>
  